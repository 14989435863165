import { Action } from '@ngrx/store';

export enum AuthActionTypes {
  Login = '[Auth] Login',
  Logout = '[Auth] Logout',
  UserRequested = '[Request User] Action',
  UserLoaded = '[Load User] User Details',
  UserProgressRequested = '[Request UserProgress] Action',
  UserProgressLoaded = '[Load UserProgress] UserProgress Details',
  AddLoginData = '[Login Data] AddLoginData'
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;
  constructor(public payload: { authToken: string }) { }
}
export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}
export class UserRequested implements Action {
  readonly type = AuthActionTypes.UserRequested;
}

export class UserLoaded implements Action {
  readonly type = AuthActionTypes.UserLoaded;
  constructor(public payload: { subject_details: [],gameplan_details:[] }) { }
}
export class UserProgressRequested implements Action {
  readonly type = AuthActionTypes.UserProgressRequested;
  constructor(public payload: { subject_id: string }) { }
}

export class UserProgressLoaded implements Action {
  readonly type = AuthActionTypes.UserProgressLoaded;
  constructor(public payload: { subject_details: [] }) { }
}

export class LoginData implements Action {
  readonly type = AuthActionTypes.AddLoginData;
  constructor(public payload: any) { }
}

export type AuthActions = Login | Logout | UserRequested | UserLoaded | UserProgressRequested | UserProgressLoaded | LoginData;
