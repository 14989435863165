<div *ngIf="loader == true" style="text-align: center;">
    <br>

    <div class="spinner-grow text-primary" role="status">
        <span class="visually-hidden"></span>
    </div>
    <div class="spinner-grow text-secondary" role="status">
        <span class="visually-hidden"></span>
    </div>
    <div class="spinner-grow text-success" role="status">
        <span class="visually-hidden"></span>
    </div>
    <div class="spinner-grow text-danger" role="status">
        <span class="visually-hidden"></span>
    </div>
    <div class="spinner-grow text-warning" role="status">
        <span class="visually-hidden"></span>
    </div>
    <div class="spinner-grow text-info" role="status">
        <span class="visually-hidden"></span>
    </div>
    <div class="spinner-grow text-light" role="status">
        <span class="visually-hidden"></span>
    </div>
    <div class="spinner-grow text-dark" role="status">
        <span class="visually-hidden"></span>
    </div>
    <br>

</div>