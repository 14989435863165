import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EllipsisPipe } from './ellipsis.pipe';
import { RoundPipe } from './round.pipe';
import { MinHrsPipe } from './min-hrs.pipe';
import { SafePipe } from './safe.pipe';
import { SumPipe } from './sum.pipe';
import { EvaluationAgencyPipe } from './evaluation-agency.pipe';
import { minutesToTime } from './mins-time';

@NgModule({
  declarations: [EllipsisPipe, RoundPipe, MinHrsPipe, SafePipe, SumPipe, EvaluationAgencyPipe, minutesToTime], // FiltersPipe, RemovewhitespacesPipe, SafePipe,SafeHtmlPipe],
  imports: [
    CommonModule
  ],
  exports: [EllipsisPipe, RoundPipe, MinHrsPipe, SafePipe,SumPipe,EvaluationAgencyPipe, minutesToTime] //FiltersPipe, RemovewhitespacesPipe, SafePipe,SafeHtmlPipe]
})
export class PipesModule { }
