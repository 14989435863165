import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'minHrs'
})
export class MinHrsPipe implements PipeTransform {

  transform(input: number) {
    if (input > 59) {
      return Math.round(input / 60) + 'Hrs Remaining';
    } else {
      return Math.round(input) + 'Min Remaining';
    }

  }

}
