import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class MacService {
  // url = localStorage.getItem('islogged_cpa') == '1' ? `${environment.apiUrl}` : localStorage.getItem('islogged_cma') == '1' ? `${environment.cmaapiUrl}`: '';
  url = `${environment.apiUrl}`
  cmaurl = `${environment.cmaapiUrl}`

  getLinkedInProfileData: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  viewCartSubject = new Subject();
  viewCardData = this.viewCartSubject.asObservable();
  constructor(private http: HttpClient) { }

  saveLikenIn(data) {
    return this.http.post(this.url + 'linkedin_save', data);
  }

  shareLinkedinPost(data) {
    return this.http.post(this.url + 'shareLinkedinPost', data);
  }

  // NEW API CALL TO MONGO

  getuserdetails() {
    return this.http.get(this.url + 'getuserdetails');
  }

  updateuser(data) {
    return this.http.put(this.url + 'updateuser', data);
  }

  milesCoinsCount() {
    return this.http.get(this.url + 'milesCoinsCount');
  }

  getrecentearnings() {
    return this.http.get(this.url + 'getrecentearnings');
  }

  getcategoriesandactivities() {
    return this.http.post(this.url + 'getcategoriesandactivities', {});
  }

  getallproducts() {
    return this.http.get(this.url + 'getallproducts');
  }

  addtocart(data) {
    return this.http.post(this.url + 'addtocart', data);
  }

  viewcart() {
    this.http.get(this.url + 'viewcart').subscribe((res: any) => {
      if (res.data && res.status) {
        this.viewCartSubject.next(res.data);
      }
    })
  }

  updatecart(data) {
    return this.http.put(this.url + 'updatecart', data);
  }

  removecart(data) {
    return this.http.post(this.url + 'removecart', data);
  }

  addaddress(data) {
    return this.http.post(this.url + 'addaddress', data);
  }

  viewaddress() {
    return this.http.get(this.url + 'viewaddress');
  }

  updateaddress(data) {
    return this.http.put(this.url + 'updateaddress', data);
  }

  removeaddress(data) {
    return this.http.delete(this.url + 'removeaddress', data);
  }

  getlinkedinpostdata() {
    return this.http.get(this.url + 'getlinkedinpostdata');
  }

  getallbadges() {
    return this.http.get(this.url + 'getallbadges');
  }

  assignprofilebadge(data) {
    return this.http.post(this.url + 'assignprofilebadge', data);
  }

  checkuserexists() {
    return this.http.get(this.url + 'checkuserexists');
  }

  createorder(data) {
    return this.http.post(this.url + 'createorder', data);
  }

  vieworders() {
    return this.http.get(this.url + 'vieworders');
  }

  calculate_viewedvideos_coins() {
    return this.http.post(this.url + 'calculate_viewedvideos_coins', {});
  }

  calculate_mcq_coins() {
    return this.http.post(this.url + 'calculate_mcq_coins', {});
  }

  calculate_essay_coins() {
    return this.http.post(this.cmaurl + 'calculate_essay_coins', {});
  }

  calculate_viewedvideos_coins_cma() {
    return this.http.post(this.cmaurl + 'calculate_viewedvideos_coins', {});
  }

  calculate_mcq_coins_cma() {
    return this.http.post(this.cmaurl + 'calculate_mcq_coins', {});
  }

  calculate_sim_coins() {
    return this.http.post(this.url + 'calculate_sim_coins', {});
  }

  getBankDetails() {
    return this.http.post(this.url + 'getBankDetails', {});
  }

  getmilespost(data) {
    return this.http.post(this.url + 'getmilespost', data);
  }

  likeapost(data) {
    return this.http.post(this.url + 'likeapost', data);
  }

  yourpost(data) {
    return this.http.get(this.url + 'yourpost?pagenumber=' + data.pagenumber);
  }

  refund_mac_coins(data) {
    return this.http.post(this.url + 'refundmaccoins', data);
  }

  get_redeem_info() {
    return this.http.post(this.url + 'get_redeem_info', {});
  }

  get_user_account_details() {
    return this.http.post(this.url + 'get_user_account_details', {});
  }

  get_feedback_mail() {
    return this.http.post(this.url + 'get_feedback_mail', {});
  }

  insertstudentfeedback(data) {
    return this.http.post(this.url + 'insertstudentfeedback', data);
  }

  userwebinarinfo() {
    return this.http.post(this.url + 'userwebinarinfo', {});
  }

  userwebinarAttendence(payload: any) {
    return this.http.post(this.url + 'userwebinarinfo', payload);
  }

  getlivesessioninfo() {
    return this.http.post(this.cmaurl + 'getlivesessioninfo', {});
  }

  registerlivesession(data) {
    return this.http.post(this.cmaurl + 'registerlivesession', data);
  }

  getlivesessioninfo_cpa() {
    return this.http.post(this.url + 'getlivesessioninfo', {});
  }

  registerlivesession_cpa(data) {
    return this.http.post(this.url + 'registerlivesession', data);
  }

  insert_qr_users(data) {
    return this.http.get(this.url + 'insert_qr_users?item_id=' + data.live_webinar_id + "&user_id=" + data.user_id);
  }

  canceluserlivesessioncma(data) {
    return this.http.post(this.cmaurl + 'canceluserlivesession', data);
  }

  canceluserlivesessioncpa(data) {
    return this.http.post(this.url + 'canceluserlivesession', data);
  }

  getregistration_countscpa(data) {
    return this.http.post(this.url + 'getregistration_counts', data);
  }

  getregistration_countscma(data) {
    return this.http.post(this.cmaurl + 'getregistration_counts', data);
  }





  getCancelAttendanceClasses(param) {
    return this.http.post(this.cmaurl + 'getcancel_attendance_classes', param);
  }

  getCancelAttendanceClassesCpa(param) {
    return this.http.post(this.url + 'getcancel_attendance_classes', param);
  }

  livesessionnotifyme(data) {
    return this.http.post(this.url + 'livesessionnotifyme', data);
  }

  insertmarksheetinfo(data) {
    return this.http.post(this.url + 'insertmarksheetinfo', data);
  }

  getusermarksheetinfo() {
    return this.http.post(this.url + 'getusermarksheetinfo', {});
  }

  updatemarksheetinfo(data) {
    return this.http.post(this.url + 'updatemarksheetinfo', data);
  }

  getjobprofiledetails() {
    return this.http.post(this.url + 'getjobprofiledetails', {});
  }

  getstudentinfo() {
    return this.http.post(this.url + 'student/details', {});
  }

  insert1o1info(data) {
    return this.http.post(this.url + 'savejobprofiledetails', data);
  }

  aicpiPayment() {
    return this.http.post(this.url + 'aicpaPaymentinformation', {});
  }

  getAttendenceBatches(data) {
    return this.http.post(this.url + 'getallavailablebatches', data)
  }

  getlmsuserinfo(){
    return this.http.post(this.url + 'getlmsuserinfo', {})
  }

  gettdspercentage(){
    return this.http.post(this.url + 'gettdspercentage', {})
  }

}
