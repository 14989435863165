<div class="modal-body scrollbar scrollbar-primary" style="padding: 0px;">
  <div class="force-overflow">
    <div class="content">
      <header>
        <h1>We would like to hear your feedback, this will help us improve and serve you better!</h1>
        <!-- 	Category Image -->
      <ng-container *ngFor="let row of categoryDropdown">
        <img *ngIf="selectedCategory == row.category_id" [src]="(row.banner_image) ? row.banner_image : bannerImage" alt="" />
      </ng-container>    
      <img *ngIf="!selectedCategory" [src]="bannerImage" alt="" />
 
      </header>
      <main>
        <button type="button" class="close pull-right" style="top: 5px; right: 10px; opacity: 0.8;" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true" style="color: #000;">×</span>
        </button>
        <!-- Category Starts -->
        <form class="feedback_form" *ngIf="!showCategoryOptions && !showThankYou">
        <!-- <p class="mb-3 text-left">Recipient:</p>
        <p class="email_id"><span class="mr-2"><i class="fa-solid fa-circle-envelope" style="font-size: 20px; color: #1f2949;"></i></span> {{userData?.email}}</p>
         -->
        <p class="mt-3 text-left">Select Category:</p>
        <div class="grid-wrapper grid-col-auto">
          <ng-container *ngFor="let row of categoryDropdown ; let i=index;">
            <label [for]="'radio-card-'+ i" class="radio-card">
              <input type="radio" name="radio-card" [id]="'radio-card-'+i" [value]="row.category_id" (click)="onCategoryChange($event,row)"/>
              <div class="card-content-wrapper">
                <span class="check-icon"></span>
                <div class="card-content">
                  <img
                    [src]="(row.icon_image) ? row.icon_image : 'https://image.freepik.com/free-vector/people-putting-puzzle-pieces-together_52683-28610.jpg'"
                    alt=""
                  />

                  <h4 [innerHTML]="row.category"></h4>
                </div>
              </div>
            </label>
         </ng-container>
          <!-- <label for="radio-card-2" class="radio-card">
            <input type="radio" name="radio-card" id="radio-card-2" />
            <div class="card-content-wrapper">
              <span class="check-icon"></span>
              <div class="card-content">
                <img src="https://image.freepik.com/free-vector/people-putting-puzzle-pieces-together_52683-28610.jpg"
                  alt=""/>
                <h4>LMS</h4>
              </div>
            </div>
          </label>

          
          <label for="radio-card-3" class="radio-card">
            <input type="radio" name="radio-card" id="radio-card-3" />
            <div class="card-content-wrapper">
              <span class="check-icon"></span>
              <div class="card-content">
                <img src="https://image.freepik.com/free-vector/people-putting-puzzle-pieces-together_52683-28610.jpg"
                  alt=""/>
                <h4>Technical Aspects</h4>
              </div>
            </div>
          </label>

           
          <label for="radio-card-4" class="radio-card">
            <input type="radio" name="radio-card" id="radio-card-4" />
            <div class="card-content-wrapper">
              <span class="check-icon"></span>
              <div class="card-content">
                <img src="https://image.freepik.com/free-vector/people-putting-puzzle-pieces-together_52683-28610.jpg"
                  alt=""/>
                <h4>Trainer Feedback</h4>
              </div>
            </div>
          </label> -->
        </div>
        <button type="button" class="mx-0 mt-4 btn submit_btn" size="md" mdbWavesEffect (click)="onNext()">Next <i class="fa-solid fa-chevron-right ml-2"></i></button>
        </form>
        <!-- Category Ends -->

        <!-- General Feedback Starts -->
        <div class="feedback_form" *ngIf="(selectedCategory == 4 || selectedCategory == 8) && showCategoryOptions">
          <div class="top_header">
            <h1 class="feedback_heading">General Feedback</h1>
            <button type="button" class="mx-0 btn submit_btn" size="sm" mdbWavesEffect style="background-color: #39c0ed !important;" (click)="addGeneralFeedback();" >SAVE and ADD <i class="fa-solid fa-plus ml-2"></i></button>
          </div>
          <div class="top_content fix_height" *ngIf="generalFeedback">

            <div class="general_feedback_box" *ngFor="let row of generalFeedback;let i=index;">
              <div class="d-flex align-items-center justify-content-between" style="border-bottom: solid 1px #c1c0c0;" (click)="onGeneralFeedbackClick(i)">
                <h3 class="general_feedback_heading">{{(generalFeedback[i].team_name) ? generalFeedback[i].team_name : 'General Feedback Details'}}</h3>
                <button *ngIf="generalFeedback.length > 1" type="button" style="background-color: #d32f2f !important; padding: 7px 12px; margin-right: 15px !important;" class="m-0 btn submit_btn" size="sm" mdbWavesEffect (click)="deleteGeneralFeedback(i);"><i class="fa-solid fa-trash-can"></i></button>
                <i class="fa-solid fa-angle-down" style="margin-right: 15px;"></i>
              </div>
              <div style="position: relative;"  [id]="'gfclose_'+i">
                <img src="http://www.ansonika.com/annova/img/shadow_top_form.png" style="position: absolute; top: 0; left: 0px;" class="img-fluid">
                <div style="padding: 15px;">
                <label class="label_text">Name of Associate/Process/Team</label>
                <input type="text" [id]="'team_name'+i" class="form-control mb-3 shadow" placeholder="Name of Associate/Process/Team" [(ngModel)]="generalFeedback[i].team_name">
                <label class="label_text">Issue/Escalation/Feedback</label>
                <div [id] = "'scrollgfid_'+i"></div>
                <textarea class="form-control shadow" slot="resize: none;" [id]="'feedback'+i" rows="3" placeholder="Issue/Escalation/Feedback" [(ngModel)]="generalFeedback[i].feedback"></textarea>
                </div>
              </div>
            </div>

          </div>
          <div style="border-top: solid 1px #c1c0c0; margin-top: 20px; display: flex; justify-content: space-between;">
            <button type="button" class="mx-0 mt-4 btn submit_btn" size="md" mdbWavesEffect (click)="onBack();"><i class="fa-solid fa-chevron-left mr-2"></i> Back</button>
            <button type="button" class="mx-0 mt-4 btn submit_btn" size="md" mdbWavesEffect (click)="sendFeedBack()">Submit <i class="fa-solid fa-chevron-right ml-2"></i></button>
          </div>
        </div>
        <!-- General Feedback Ends -->

      
        <!-- LMS Feedback Starts 
             Technical Aspects Feedback Starts -->

        <div class="feedback_form" *ngIf="(['3','4','7','8'].indexOf(selectedCategory) == -1) && showCategoryOptions">
          <div class="top_header">
            <h1 class="feedback_heading">{{selectedCategoryName}} Feedback</h1>
            <button type="button" size="sm" class="mx-0 btn submit_btn" style="background-color: #39c0ed !important;" (click)="addQuestionFeedback()">SAVE and ADD 
              <i class="fa-solid fa-plus ml-2"></i></button>
          </div>
          <div class="top_content fix_height">
            <div class="general_feedback_box" style="display: grid;" *ngFor="let row of questionFeedback;let i=index;">

              <!-- <div style="padding: 15px;"> -->
                <!-- <select class="browser-default custom-select" style="background-color: #fff; border: 1px solid #D1D6EE; box-shadow: 0 1px 6px -1px rgb(0 9 61 / 16%);">
                  <option selected>Select</option>
                  <option value="1">Study Planner</option>
                  <option value="2">Videos</option>
                  <option value="3">MCQ</option>
                  <option value="4">Simulations</option>
                  <option value="5">Game Plan</option>
                  <option value="6">Webinar</option>
                  <option value="7">FAB</option>
                  <option value="8">Offline Class</option>
                  <option value="9">Mentoring</option>
                  <option value="10">Forum</option>
                  <option value="11">Practise Exam</option>
                  <option value="12">Bridge Course</option>
                  <option value="13">MAC</option>
                  <option value="14">Books</option>
                  <option value="15">FACS</option>
                  <option value="16">UPS</option>
                  <option value="17">Content Updates</option>
                </select> -->
              <!-- </div> -->

              <div>
                <div  class="d-flex align-items-center justify-content-between" style="border-bottom: solid 1px #c1c0c0;" (click)="onQuestionFeedbackClick(i)">
                  <h3 class="general_feedback_heading">{{questionFeedback[i].question_name ? questionFeedback[i].question_name : selectedCategoryName +' Feedback'}} Details</h3>
                  <button *ngIf="questionFeedback.length > 1" type="button" style="background-color: #d32f2f !important; padding: 7px 12px; margin-right: 15px !important;float: right" class="btn submit_btn" size="sm" mdbWavesEffect (click)="deleteQuestionFeedback(i)"><i class="fa-solid fa-trash-can"></i></button>
                  <i class="fa-solid fa-angle-down" style="margin-right: 15px;"></i>
                </div>
                <div style="position: relative;" [id]="'question_close_'+i">
                  <div class="emojiratings" style="padding: 15px;">
                    <label class="label_text">Choose Category 
                    </label>
                    <ng-select class="gamplan-custom-ngSelect shadow border-none text-left" [items]="categoryQuestionList" [multiple]="false" bindLabel="question"
                    bindValue="category_id" placeholder="Select" [clearable]="false" (change)="onQuestionChange($event,i)">
                    </ng-select>

                    <ng-container *ngIf="[6,9,31,34].includes(this.questionFeedback[i]['question_id'])">
                     <a [href]="FeedbackUrl[i]['feedback_url']" target="_blank"><button  type="button" size="sm" class="mx-0 mt-4 btn submit_btn" style="background-color: #39c0ed !important;">share feedback</button></a>
                   </ng-container>

                    <div [id] = "'scrollQueid_'+i"></div>

                    <ng-container *ngIf="this.questionFeedback[i]['question_name'] && ![6,9,31,34].includes(this.questionFeedback[i]['question_id'])">
                      <div class="emojiratings_left mt-1">
                        <div class="row ml-1"><i
                            class="fa-solid fa-caret-left mr-1 mt-1"></i><span>
                            <p>{{questionFeedback[i].question_name}}</p>
                          </span>
                        </div>
                      </div>
                      <div class="emojiratings_right">
                        <ul>
                          <li [id]="'rating_1_'+i" (click)="getQuestionRatingData(1,row,i)" [ngClass]="(this.questionFeedback?.[i]?.['rating'] == 1) ? 'active' :''">
                            <i class="fa-solid fa-face-anxious-sweat"></i>
                            <span>Terrible</span>
                          </li>
                          <li [id]="'rating_2_'+i" (click)="getQuestionRatingData(2,row,i)" [ngClass]="(this.questionFeedback?.[i]?.['rating'] == 2) ? 'active' :''">
                            <i class="fa-solid fa-face-frown-slight"></i>
                            <span>Bad</span>
                          </li>
                          <li [id]="'rating_3_'+i" (click)="getQuestionRatingData(3,row,i)" [ngClass]="(this.questionFeedback?.[i]?.['rating'] == 3) ? 'active' :''">
                            <i class="fa-solid fa-face-expressionless"></i>
                            <span>Okay</span>
                          </li>
                          <li [id]="'rating_4_'+i" (click)="getQuestionRatingData(4,row,i)" [ngClass]="(this.questionFeedback?.[i]?.['rating'] == 4) ? 'active' :''">
                            <i class="fa-solid fa-face-smile"></i>
                            <span>Good</span>
                          </li>
                          <li [id]="'rating_5_'+i" (click)="getQuestionRatingData(5,row,i)" [ngClass]="(this.questionFeedback?.[i]?.['rating'] == 5) ? 'active' :''">
                            <i class="fa-solid fa-face-laugh"></i>
                            <span>Amazing</span>
                          </li>
                        </ul>
                      </div>
                    </ng-container>
                  </div>
    
                  <ng-container *ngIf="this.questionFeedback?.[i]?.['rating']">
                  <div style="padding: 0px 15px 15px;" *ngIf="subquestionList[i]?.length > 0">
                    <label class="label_text" *ngIf="subquestionList[i]?.length > 0 && this.questionFeedback?.[i]?.['rating'] == 5"><i class="fa-solid fa-caret-left mr-1 mt-1"></i> What went right?</label>
                    <label class="label_text" *ngIf="subquestionList[i]?.length > 0 && this.questionFeedback?.[i]?.['rating'] < 5 && this.questionFeedback?.[i]?.['rating'] > 0"><i class="fa-solid fa-caret-left mr-1 mt-1"></i> What went wrong?</label>

                    <div class="right_option">
                      <ul>
                        <li *ngFor="let row of subquestionList[i];let q=index">
                          <!-- <input type="checkbox" id="checkbox2" name="checkbox01" checked="checked"> -->
                          <input type="checkbox" class="messageCheckbox" [id]="'checkbox'+q+'_'+i" [name]="'checkbox01'+i" (click)="getSelectedSubQuestions($event,row,i)">
                          <label [for]="'checkbox'+q+'_'+i" class="checkbox_label">{{row.sub_question}}</label>
                        </li>
                        <!-- <li>
                          <input type="checkbox" [id]="'checkbox2'+i" [name]="'checkbox01'+i">
                          <label [for]="'checkbox2'+i" class="checkbox_label">Questions are clear </label>
                        </li>
                        <li>
                          <input type="checkbox" [id]="'checkbox3'+i" [name]="'checkbox01'+i">
                          <label [for]="'checkbox3'+i" class="checkbox_label">Adequate number of questions</label>
                        </li> -->
                        <!-- <li>
                          <input type="checkbox" id="checkbox4" name="checkbox01">
                          <label for="checkbox4" class="checkbox_label">All concepts are tested</label>
                        </li> -->
                      </ul>
                    </div>
                  </div>
                  </ng-container>
                  <!-- <div style="padding: 0px 15px 15px;">
                    <label class="label_text"><i class="fa-solid fa-caret-left mr-1 mt-1"></i> What went wrong?</label>
                    <div class="wrong_option">
                      <ul>
                        <li>
                          <input type="checkbox" id="checkbox5" name="checkbox02">
                          <label for="checkbox5" class="checkbox_label">Easy of Use</label>
                        </li>
                        <li>
                          <input type="checkbox" id="checkbox6" name="checkbox02">
                          <label for="checkbox6" class="checkbox_label">Relevance</label>
                        </li>
                      </ul>
                    </div>
                  </div> -->
                </div>
              </div>

             

              <!-- <div style="position: relative; display: none;">
                <form style="padding: 15px;">
                  <div class="text-left">
                    <ng-container *ngFor="let row of categoryQuestionList;let i=index;">
                      <div class="emojiratings">
                        <div class="emojiratings_left">
                          <div class="row ml-1">
                            <i class="fa-solid fa-caret-left mr-1 mt-1"></i>
                            <span [innerHTML]="row.question"></span>
                          </div>
                        
                        </div>
                        <div class="emojiratings_right">
                          <ul>
                            <li [id]="'rating_1_'+i" (click)="feedbackData(1,row)" [ngClass]="(this.sendStarRating[row.question_id]['rating'] == 1) ? 'active' :''">
                              <i class="fa-solid fa-face-anxious-sweat"></i>
                              <span>Terrible</span>
                            </li>
                            <li [id]="'rating_2_'+i" (click)="feedbackData(2,row)" [ngClass]="(this.sendStarRating[row.question_id]['rating'] == 2) ? 'active' :''">
                              <i class="fa-solid fa-face-frown-slight"></i>
                              <span>Bad</span>
                            </li>
                            <li [id]="'rating_3_'+i" (click)="feedbackData(3,row)" [ngClass]="(this.sendStarRating[row.question_id]['rating'] == 3) ? 'active' :''">
                              <i class="fa-solid fa-face-expressionless"></i>
                              <span>Okay</span>
                            </li>
                            <li [id]="'rating_4_'+i" (click)="feedbackData(4,row)" [ngClass]="(this.sendStarRating[row.question_id]['rating'] == 4) ? 'active' :''">
                              <i class="fa-solid fa-face-smile"></i>
                              <span>Good</span>
                            </li>
                            <li [id]="'rating_5_'+i" (click)="feedbackData(5,row)" [ngClass]="(this.sendStarRating[row.question_id]['rating'] == 5) ? 'active' :''">
                              <i class="fa-solid fa-face-laugh"></i>
                              <span>Amazing</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr *ngIf="(categoryQuestionList.length-1) !== i">
                   </ng-container>
                   </div>
                </form>
              </div> -->

            </div>

          </div>
          <div style="border-top: solid 1px #c1c0c0; margin-top: 20px; display: flex; justify-content: space-between;">
            <button type="button" class="mx-0 mt-4 btn submit_btn" size="md" mdbWavesEffect (click)="onBack();"><i class="fa-solid fa-chevron-left mr-2"></i> Back</button>
            <button type="button" class="mx-0 mt-4 btn submit_btn" size="md" mdbWavesEffect (click)="sendFeedBack()">Submit <i class="fa-solid fa-chevron-right ml-2"></i></button>
          </div>
        </div>
        <!-- LMS Feedback Ends -->


        <!-- Trainer Feedback Starts -->
        <div class="feedback_form" *ngIf="(selectedCategory == 3 || selectedCategory == 7) && showCategoryOptions">
          <div class="top_header">
            <h1 class="feedback_heading">Trainer Feedback</h1>
            <button type="button" class="mx-0 btn submit_btn" size="sm" mdbWavesEffect style="background-color: #39c0ed !important;" (click)="addTrainerFeedback();">SAVE and ADD <i class="fa-solid fa-plus ml-2"></i></button>
          </div>
          <div class="top_content fix_height" *ngIf="trainerFeedback">
            <div class="general_feedback_box" *ngFor="let tr of trainerFeedback;let t=index;">

              <div class="d-flex align-items-center justify-content-between" [id]="'tf_'+t" style="border-bottom: solid 1px #c1c0c0;" (click)="onTrainerFeedbackClick(t)">
                <h3 class="general_feedback_heading">{{(trainerFeedback[t].trainer_id  && trainerName[trainerFeedback[t].trainer_id] ) ? trainerName[trainerFeedback[t].trainer_id]['name'] :'Trainer Feedback Details'}}</h3>
                <button *ngIf="trainerFeedback.length > 1" type="button" style="background-color: #d32f2f !important; padding: 7px 12px; margin-right: 15px !important;" class="m-0 btn submit_btn" size="sm" mdbWavesEffect (click)="deleteTrainerFeedback(t)"><i class="fa-solid fa-trash-can"></i></button>
                <i class="fa-solid fa-angle-down" style="margin-right: 15px;"></i>
              </div>
              <div style="position: relative;" [id]="'tfclose_'+t">
                <img src="http://www.ansonika.com/annova/img/shadow_top_form.png" style="position: absolute; top: 0; left: 0px;" class="img-fluid">
                <div style="padding: 15px;">
                  <div class="text-left">
                    <div class="mb-3">
                      <label class="label_text">Trainer</label>
                      <ng-select class="gamplan-custom-ngSelect shadow border-none" [items]="trainerDropdown" [multiple]="false" bindLabel="name"
                      bindValue="trainer_id" placeholder="Select Trainer" [clearable]="false" 
                      [(ngModel)]="trainerFeedback[t].trainer_id">
                      </ng-select>
                    </div>
        
                    <!-- <div class="mb-3">
                      <label class="label_text">Course</label>
                      <ng-select class="gamplan-custom-ngSelect shadow border-none" [items]="courseList" [multiple]="false" bindLabel="value"
                      bindValue="id" placeholder="Select Course" [clearable]="false" 
                      [(ngModel)]="trainerFeedback[t].course" disabled>
                      </ng-select>
                    </div> -->
        
                    <div class="mb-3">
                      <label class="label_text">Subject</label>
                      <ng-select class="gamplan-custom-ngSelect shadow border-none" [items]="subjectList" [multiple]="false" bindLabel="value"
                      bindValue="id" placeholder="Select Subject" [clearable]="false"
                      [(ngModel)]="trainerFeedback[t].subject_id">
                      </ng-select>
                    </div>
                    <div [id] = "'scrollid_'+t"></div>

                    <div class="mb-3">
                      <label class="label_text">Mode Of Class</label>
                      <ng-select class="gamplan-custom-ngSelect shadow border-none" [items]="classModeList" [multiple]="false" bindLabel="value"
                      bindValue="id" placeholder="Select Mode of Class" [clearable]="false"
                      [(ngModel)]="trainerFeedback[t].class_mode">
                      </ng-select>
                    </div>
        
                    <div class="form-group mb-3">
                      <textarea class="form-control shadow" id="exampleFormControlTextarea2" rows="3"
                        placeholder="Message" [(ngModel)]="trainerFeedback[t].message"></textarea>
                    </div>

                    <!-- <label class="label_text">Knowledge on the Subject of Trainer</label> -->
                    <ng-container *ngFor="let row of categoryQuestionList;let i=index;">
                      <div class="emojiratings">
                        <div class="emojiratings_left">
                          <div class="row ml-1 flex-nowrap">
                            <i class="fa-solid fa-caret-left mr-1 mt-1"></i>
                            <span [innerHTML]="row.question"></span>
                          </div>
                        
                        </div>
                        <div class="emojiratings_right">
                          <ul>
                            <li [id]="'rating_1_'+i" (click)="multifeedbackData(1,row,t)" [ngClass]="(this.trainerFeedback?.[t]?.['question']?.[row.question_id] == 1) ? 'active' :''">
                              <i class="fa-solid fa-face-anxious-sweat"></i>
                              <span>Terrible</span>
                            </li>
                            <li [id]="'rating_2_'+i" (click)="multifeedbackData(2,row,t)" [ngClass]="(this.trainerFeedback?.[t]?.['question']?.[row.question_id] == 2) ? 'active' :''">
                              <i class="fa-solid fa-face-frown-slight"></i>
                              <span>Bad</span>
                            </li>
                            <li [id]="'rating_3_'+i" (click)="multifeedbackData(3,row,t)" [ngClass]="(this.trainerFeedback?.[t]?.['question']?.[row.question_id] == 3) ? 'active' :''">
                              <i class="fa-solid fa-face-expressionless"></i>
                              <span>Okay</span>
                            </li>
                            <li [id]="'rating_4_'+i" (click)="multifeedbackData(4,row,t)" [ngClass]="(this.trainerFeedback?.[t]?.['question']?.[row.question_id] == 4) ? 'active' :''">
                              <i class="fa-solid fa-face-smile"></i>
                              <span>Good</span>
                            </li>
                            <li [id]="'rating_5_'+i" (click)="multifeedbackData(5,row,t)" [ngClass]="(this.trainerFeedback?.[t]?.['question']?.[row.question_id] == 5) ? 'active' :''">
                              <i class="fa-solid fa-face-laugh"></i>
                              <span>Amazing</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <hr *ngIf="(categoryQuestionList.length-1) !== i">

                     </ng-container>

                    <!-- <div class="emojiratings">
                      <div class="emojiratings_left"><i class="fa-solid fa-caret-left mr-1"></i> Body Language/teaching style</div>
                      <div class="emojiratings_right">
                        <ul>
                          <li>
                            <i class="fa-solid fa-face-anxious-sweat"></i>
                            <span>Terrible</span>
                          </li>
                          <li>
                            <i class="fa-solid fa-face-frown-slight"></i>
                            <span>Bad</span>
                          </li>
                          <li>
                            <i class="fa-solid fa-face-expressionless"></i>
                            <span>Okay</span>
                          </li>
                          <li>
                            <i class="fa-solid fa-face-smile"></i>
                            <span>Good</span>
                          </li>
                          <li class="active">
                            <i class="fa-solid fa-face-laugh"></i>
                            <span>Amazing</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <hr>

                    <div class="emojiratings">
                      <div class="emojiratings_left"><i class="fa-solid fa-caret-left mr-1"></i> Preparation for the class</div>
                      <div class="emojiratings_right">
                        <ul>
                          <li>
                            <i class="fa-solid fa-face-anxious-sweat"></i>
                            <span>Terrible</span>
                          </li>
                          <li>
                            <i class="fa-solid fa-face-frown-slight"></i>
                            <span>Bad</span>
                          </li>
                          <li>
                            <i class="fa-solid fa-face-expressionless"></i>
                            <span>Okay</span>
                          </li>
                          <li class="active">
                            <i class="fa-solid fa-face-smile"></i>
                            <span>Good</span>
                          </li>
                          <li>
                            <i class="fa-solid fa-face-laugh"></i>
                            <span>Amazing</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <hr>
                    
                    <div class="emojiratings">
                      <div class="emojiratings_left"><i class="fa-solid fa-caret-left mr-1"></i> Punctuality/Attendance</div>
                      <div class="emojiratings_right">
                        <ul>
                          <li>
                            <i class="fa-solid fa-face-anxious-sweat"></i>
                            <span>Terrible</span>
                          </li>
                          <li>
                            <i class="fa-solid fa-face-frown-slight"></i>
                            <span>Bad</span>
                          </li>
                          <li>
                            <i class="fa-solid fa-face-expressionless"></i>
                            <span>Okay</span>
                          </li>
                          <li>
                            <i class="fa-solid fa-face-smile"></i>
                            <span>Good</span>
                          </li>
                          <li class="active">
                            <i class="fa-solid fa-face-laugh"></i>
                            <span>Amazing</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <hr>

                    <div class="emojiratings">
                      <div class="emojiratings_left"><i class="fa-solid fa-caret-left mr-1"></i> Keeps Video/Audio on at all times</div>
                      <div class="emojiratings_right">
                        <ul>
                          <li>
                            <i class="fa-solid fa-face-anxious-sweat"></i>
                            <span>Terrible</span>
                          </li>
                          <li>
                            <i class="fa-solid fa-face-frown-slight"></i>
                            <span>Bad</span>
                          </li>
                          <li>
                            <i class="fa-solid fa-face-expressionless"></i>
                            <span>Okay</span>
                          </li>
                          <li class="active">
                            <i class="fa-solid fa-face-smile"></i>
                            <span>Good</span>
                          </li>
                          <li>
                            <i class="fa-solid fa-face-laugh"></i>
                            <span>Amazing</span>
                          </li>
                        </ul>
                      </div>
                    </div> -->

                </div>
              </div>
              </div>
            </div>

          </div>
          <div style="border-top: solid 1px #c1c0c0; margin-top: 20px; display: flex; justify-content: space-between;">
            <button type="button" class="mx-0 mt-4 btn submit_btn" size="md" mdbWavesEffect (click)="onBack();"><i class="fa-solid fa-chevron-left mr-2"></i> Back</button>
            <button type="button" class="mx-0 mt-4 btn submit_btn" size="md" mdbWavesEffect (click)="sendFeedBack()">Submit <i class="fa-solid fa-chevron-right ml-2"></i></button>
          </div>
        </div>
        <!-- Trainer Feedback Ends -->

        <!-- Thankyou Feedback Starts -->

        <div class="feedback_form text-center" *ngIf="showThankYou">
          <img src="https://storage.googleapis.com/miles-next_website-lms-gptool/cpa-lms/feedback/thank_you.png" class="img-fluid mt-5">
          <h1 class="mt-2" style="font-weight: 600; font-size: 2rem; color: #0070f0;">Thank You!</h1>
          <p class="mt-3">We’re so happy to hear from you! Thank you for your valuable feedback.</p>
          <button type="button" class="mx-0 mt-4 btn submit_btn" size="md" mdbWavesEffect (click)="onBack();">Back To Home</button>
        </div>

        <!-- Thankyou Feedback Ends -->

      </main>
    </div>
  </div>
</div>

