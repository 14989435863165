<div class="div">

    <div class="modal-header py-1">
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide();"
          style="margin-top: -12px;">
          <i class="fa fa-times" aria-hidden="true"></i>
        </button>
        <span class="modal-title w-100" id="myModalLabel" style="font-weight: normal;font-size: 1.3rem !important;">
          Notification
        </span>
      </div>

    
  <div class="modal-body">
    
    <div class="notification_contentggggg">

            <div
                *ngIf="notificationData.length"
                class="notification-header font-weight-bold d-flex justify-content-between">
            <span class="my-auto">NEW</span>
            <button
                    size="sm"
                    color="link"
                    mdbBtn
                    mdbWavesEffects
                    class="m-0 font-weight-bold"
                    (click)="marksNotificaionsread(0)"
                    style="font-size: 11px; padding: 0.2rem 0.5rem; color: #2d6fdc !important">
                Clear all
            </button>
            </div>
            <ng-container *ngIf="notificationData.length">
            <ng-container *ngFor="let row of notificationData">
                <ng-container *ngIf="row.is_post_closed === 0">
                <div class="alert alert-info mb-0" role="alert" *ngIf="row.is_forum_admin === 0">
                    <a
                        (click)="
                        marksNotificaionsread(row.int_post_id);
                        goToForum(
                        row.int_thread_type_id,
                        row.int_subject_id,
                        row.txt_question_id,
                        row.int_topic_id,
                        row.int_subtopic_id,
                        row.int_video_id
                        )
                    ">
                    <div class="pr-2">
                        <i
                            class="fad"
                            [ngClass]="row.is_post_r_reply === 1 ? 'fa-comment-alt-lines' : 'fa-comments-alt'"
                            style="font-size: 1.2rem"></i>
                    </div>
                    <div *ngIf="row.is_post_r_reply === 1">
                        <b>{{ row.txt_name }}</b> replied to your
                        <b>{{
                        row.int_thread_type_id == 1
                        ? 'mcq'
                        : row.int_thread_type_id == 2
                        ? 'sim'
                        : row.int_thread_type_id == 3
                        ? 'video'
                        : row.int_thread_type_id == 4
                        ? 'ebook'
                        : ''
                        }}</b>
                        post
                    </div>
                    <div *ngIf="row.is_post_r_reply != 1">
                        <b>{{ row.txt_name }}</b> replied to your reply on
                        <b>{{
                        row.int_thread_type_id == 1
                        ? 'mcq'
                        : row.int_thread_type_id == 2
                        ? 'sim'
                        : row.int_thread_type_id == 3
                        ? 'video'
                        : row.int_thread_type_id == 4
                        ? 'ebook'
                        : ''
                        }}</b>
                        post
                    </div>
                    </a>
                </div>
                <div class="alert alert-primary mb-0" role="alert" *ngIf="row.is_forum_admin === 1">
                    <a
                        (click)="
                        marksNotificaionsread(row.int_post_id);
                        goToForum(
                        row.int_thread_type_id,
                        row.int_subject_id,
                        row.txt_question_id,
                        row.int_topic_id,
                        row.int_subtopic_id,
                        row.int_video_id
                        )
                    ">
                    <div class="pr-2">
                        <i
                            class="fad"
                            [ngClass]="row.is_post_r_reply === 1 ? 'fa-comment-lines' : 'fa-comments'"
                            style="font-size: 1.2rem"></i>
                    </div>
                    <div *ngIf="row.is_post_r_reply === 1">
                        <b>{{ row.txt_name }}</b> replied to your
                        <b>{{
                        row.int_thread_type_id == 1
                        ? 'mcq'
                        : row.int_thread_type_id == 2
                        ? 'sim'
                        : row.int_thread_type_id == 3
                        ? 'video'
                        : row.int_thread_type_id == 4
                        ? 'ebook'
                        : ''
                        }}</b>
                        post
                    </div>
                    <div *ngIf="row.is_post_r_reply != 1">
                        <b>{{ row.txt_name }}</b> replied to your reply on
                        <b>{{
                        row.int_thread_type_id == 1
                        ? 'mcq'
                        : row.int_thread_type_id == 2
                        ? 'sim'
                        : row.int_thread_type_id == 3
                        ? 'video'
                        : row.int_thread_type_id == 4
                        ? 'ebook'
                        : ''
                        }}</b>
                        post
                    </div>
                    </a>
                </div>
                </ng-container>
                <ng-container *ngIf="row.is_post_closed === 1">
                <div class="alert alert-success mb-0" role="alert">
                    <a
                        (click)="
                        marksNotificaionsread(row.int_post_id);
                        goToForum(
                        row.int_thread_type_id,
                        row.int_subject_id,
                        row.txt_question_id,
                        row.int_topic_id,
                        row.int_subtopic_id,
                        row.int_video_id
                        )
                    "
                        style="text-decoration: none !important"
                        *ngIf="row.is_post_closed === 1">
                    <div class="pr-2">
                        <div
                            class="font-weight-bold success-color-dark my-auto text-center px-2 text-white"
                            style="font-size: 20px">
                        R
                        </div>
                    </div>
                    <div class="my-auto" style="text-decoration: underline">
                        <b>{{ row.txt_name }}</b> marked your
                        <b>{{
                        row.int_thread_type_id == 1
                        ? 'mcq'
                        : row.int_thread_type_id == 2
                        ? 'sim'
                        : row.int_thread_type_id == 3
                        ? 'video'
                        : row.int_thread_type_id == 4
                        ? 'ebook'
                        : ''
                        }}</b>
                        post as resolved
                    </div>
                    </a>
                </div>
                </ng-container>
            </ng-container>
            </ng-container>
            <div class="text-center px-3 py-1" *ngIf="!notificationData.length">
                <div class="mx-auto mb-2" style="width: 130px">
                    <img src="/assets/img/Notification.png" class="img-fluid" alt="No Notification Banner" />
                </div>
                <span class="d-block h6 small notification_no   ">No new notification</span>
            </div>
    </div>
  </div>
</div>

