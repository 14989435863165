import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import Swal from 'sweetalert2';
import { ActivatedRoute } from '@angular/router';
// const paypal = require('paypal-rest-sdk');
// paypal.configure({
//     'mode': 'sandbox',
//     'client_id': 'AQs0dw9JCJhDLe6Mbd2QbmrHG2BA0mfkK7DUcH94r5UL3SCOHnO021XHyDORL9jaUtQRHDM4b5Nl9Dpy',
//     'client_secret': 'ENNq5rUiPoN0WAY9gwN1tgIFFPOhEQqW-z1CE-sEtJO9i5zD1xh5cG_btQ8YRiPliNyHSbUQOSp_JsFX'
// });

@Injectable({
	providedIn: 'root',
})
export class PaymentService {
	Url = `${environment.apiUrl}`;
	// part1 = '41d4d05f967c1b913cabe95876b8a2c7';
	// part2 = '6c7ccca37633e15249a4ba916d82317e';
	// farSubjectId = 'a1a8cddccb57f50303507da5087ed062';
	// regSubjectId = '5fdf99246bcbd70b7fdb6b6b600e6d35';
	// ebook: BehaviorSubject<any> = new BehaviorSubject([]);
	// webinarVideoList: Subject<any> = new Subject();
	// ebookSubtopicName: BehaviorSubject<any> = new BehaviorSubject([]);
	// ebookMetadata: BehaviorSubject<any> = new BehaviorSubject([]);
	// Breadcrumbs: BehaviorSubject<any> = new BehaviorSubject([]);
	constructor(private http: HttpClient, private route: ActivatedRoute) { }
	paymentForFAC(payload) {
		// const create_payment_json = {
		// 	"intent": "sale",
		// 	"payer": {
		// 		"payment_method": "paypal"
		// 	},
		// 	"redirect_urls": {
		// 		"return_url": "http://localhost:4200/lms/fac-payment-success",
		// 		"cancel_url": "http://localhost:4200/fac-payment-cancel"
		// 	},
		// 	"transactions": [{
		// 		"item_list": {
		// 			"items": [{
		// 				"name": "Red Sox Hat",
		// 				"sku": "001",
		// 				"price": "2.00",
		// 				"currency": "USD",
		// 				"quantity": 1
		// 			}]
		// 		},
		// 		"amount": {
		// 			"currency": "USD",
		// 			"total": "2.00"
		// 		},
		// 		"description": "Hat for the best team ever"
		// 	}]
		// };
		// paypal.payment.create(create_payment_json, function (error, payment) {
		// 	if (error) {
		// 		throw error;
		// 	} else {
		// 		// console.log("payment", payment);
		// 		for (let i = 0; i < payment.links.length; i++) {
		// 			if (payment.links[i].rel === 'approval_url') {
		// 				window.location = payment.links[i].href
		// 				// console.log('urll.. ', payment.links[i].href)
		// 				// response.createResponse(res, payment.links[i].href, 'success', true, [], req, 200);
		// 				// res.redirect(payment.links[i].href);
		// 			}
		// 		}
		// 	}
		// });
		
		return this.http.post(this.Url + 'fac-payment', payload);
	}


	paymentExecuteForFAC() {
		var payload
		let PayerID
		let paymentId
		let amount
		let stateboard_id
		this.route.queryParams.subscribe(params => {
			paymentId = params['paymentId'];
			PayerID = params['PayerID'];
			amount = params['amount'];
			stateboard_id = params['stateboard_id'];
			payload = {
				PayerID,
				paymentId,
				amount,
				stateboard_id
			}
		});

		// const execute_payment_json = {
		// 	"payer_id": PayerID,
		// 	"transactions": [{
		// 		"amount": {
		// 			"currency": "USD",
		// 			"total": "55.00"
		// 		}
		// 	}]
		// };
		// paypal.payment.execute(paymentId, execute_payment_json, function (error, payment) {
		// 	if (error) {
		// 		console.log(error.response);
		// 		throw error;
		// 	} else {
		// 		// console.log(JSON.stringify(payment));
		// 		// res.send('Success');
		// 		return payment
		// 	}
		// });
		// return
		return this.http.post(this.Url + 'fac-payment-execute', {payload});
	}

	paymentDetailsForFAC() {
		return this.http.post(this.Url + 'fac-payment-details', {});
	}

}
