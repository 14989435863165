import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { delay } from 'rxjs/operators';
import { GamePlanService } from 'src/app/_clusters/services/game-plan.service';
import Swal from 'sweetalert2';
import { NgSelectComponent } from '@ng-select/ng-select';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Observable } from 'rxjs';
import * as _ from 'underscore';

@Component({
  selector: 'app-facs-payment-modal',
  templateUrl: './facs-payment-modal.component.html',
  styleUrls: ['./facs-payment-modal.component.scss']
})
export class FacsPaymentModalComponent implements OnInit {
 @ViewChild('stateboardId') ngSelectComponent: NgSelectComponent;
  userData: any = {};
  stateboardArray: any = [];
  stateBoardId: any;
  planDetails: any = {};
  transcript_email: string;
  evaluation_email: string;
  agency: any;
  stateBoardValue: any;
  stateboardValuelist = {13:'Guam (GU)',37:'North Dakota (ND)',41:'Pennsylvania (PA)',22:'Maine'};
  evaluationAgency = {13:15, 37:17, 41:16, 22:28}
  stateEvalStatus: any;
  facs_code: any;
  showFacs: boolean = false;
  changeNgSelectStatus: any = true;
  isCopied: boolean = false;
  modalRef: MDBModalRef;
  studentDetails: Observable<Array<any>>;
  constructor(private store: Store<any>, private rout: ActivatedRoute, private gamePlanService: GamePlanService, private router: Router, private modalService: MDBModalService) { }

  ngOnInit(): void { window.scroll({top:0,left:0,behavior:"smooth"});
    this.studentDetails = this.store.select((store: any) => store.student.student_details);
    this.studentDetails.subscribe((data: any) => {this.userData = data;});
    this.getStateBoardData();
    this.getstudent_facsdetails();
    // console.log(this.showFacs);
  }

  // ************** Evaluation Modal Query Begins *****************//
getStateBoardData(){
  this.gamePlanService.getStateBoard();
    this.gamePlanService.stateBoardSubject.subscribe((res: any) => {
      if (!_.isEmpty(res)) {
        this.stateboardArray = res;
        this.stateboardArray.forEach((x) => {
          x.stateboardName = x.name +' '+x.short_code;
        });
      }
    });
    this.getstudent_facsdetails();
}
onchangeStateBoard(value){
  const stateboardIdlist =[13,37,41];  //[13,37,41,22]; only guam and nd allowed from 2024
  this.stateBoardId = value.state_board_id;
  var temp = stateboardIdlist.includes(this.stateBoardId)?true:false;
  this.agency = 'Foreign Academic Credentials Service (FACS)';
  this.stateBoardValue = this.stateboardValuelist[this.stateBoardId];
  if(temp){
   this.stateEvalStatus = 1;
  }
  else{
    this.stateEvalStatus = 2;
  }
}

stateBoardStatus(){
  this.sendEvaluationAdviceOnly(this.evaluationAgency[this.stateBoardId],this.stateBoardValue);
}




sendEvaluationAdviceOnly(value,stateboard){


  this.transcript_email = `<p class="my-3">We've got some great news for you - you do <strong>NOT</strong> need to get your university transcripts. Just need notarized copies.</p>
  <p class="mb-3">Please go to a local Notary Public (Advocate) and get photocopies of all your marksheets and degree certificates notarized.
  Once you have the notarized copies, please follow the steps as detailed in Evaluation Instructions.</p>

  <p>If you have any queries, message us on the "<strong>Dashboard</strong>" tab or schedule a <strong>One-on-one Mentoring session</strong> session with the Gameplan team on the LMS. We're here to help!</p>
  <br>
  <p>Cheers,</p>`;


  this.evaluation_email = `
      <p class="my-3">Hope you are doing well. </p>
      <p class="mb-3">You are advised to proceed with the state board of <strong>${stateboard}</strong> for CPA examination. Your suggested evaluation agency is <strong>${this.agency}</strong>.</p>
      <p class="mb-3">Please follow the steps as detailed below to proceed further with your evaluation process.</p>
      <p>Few points to remember: </p>
      <ul>
        <li>The names on the marksheet and/or transcripts must match your passport. In case it does not, then an affidavit must be sent along with the notarised documents.</li>
        <li>Copy of passport (first page and last page) must be sent with all documents. In the absence of a passport, any other government authorized ID such as an Aadhar Card copy can also be sent. However, the original passport is a required proof of identity to appear for the exams.</li>
        <li>The normal process time for education evaluation is generally 4-8 weeks. Once completed, one copy of your evaluation report is mailed to the state board and the other copy will be emailed to you.</li>
        <li>After submitting the online form, you will receive an application ID. The application ID must be mentioned  on the envelope containing the documents that is to be couriered to the evaluation agency.</li>
        <li>Notice to Schedule (NTS) is the next step after issuance of your evaluation report. The instructions and payment details will be shared after the evaluation process is completed. </li>
      </ul>
      <p class="mb-2">Once your evaluation is completed, please upload a copy of the evaluation report on the "<strong>Upload Evaluation Report</strong>" tab on the LMS.</p>
      <p>If you have any queries, message us on the "<strong>Dashboard</strong>" tab or schedule a <strong>One-on-one Mentoring session</strong> session with the Gameplan team on the LMS. We're here to help!</p>
      <br>
      <p>Cheers,</p>`;



  this.planDetails.call_from = "lms";
  this.planDetails.student_id = this.userData.otb_id;
  this.planDetails.status = 3;
  this.planDetails.message = 'Verified';
  this.planDetails.transcript_email = JSON.stringify({ 'transcript_email': this.transcript_email, 'additional': '' });
  this.planDetails.evaluation_email = JSON.stringify({ 'evaluation_email': this.evaluation_email, 'additional': '' });
  this.planDetails.evaluation_agency = value.toString();
  this.gamePlanService.updateEducationStatus(this.planDetails).subscribe(async (res: any) => {
    if (res && res.status) {
       await delay(5000);
       let currentUrl = '/lms/miles-facs-payment';
       this.router.routeReuseStrategy.shouldReuseRoute = () => false;
       this.router.onSameUrlNavigation = 'reload';
       this.router.navigate([currentUrl]);
       this.hide();
    }
    else{
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: res.message,
      });
    }
  });

}


getstudent_facsdetails(){
  this.gamePlanService.student_facsdetails().subscribe((res: any) => {
          if(res.data.length && res.status){
            this.facs_code = res.data[0]?.facs_code;
            this.showFacs = true;
          }else {
            this.showFacs = false;
          }
  }, (err) => {
          this.showFacs = false;
  })
}

hide() {
  this.modalService.hide(1);
}
// *************** Evaluation Modal Query Ends ******************//

}
