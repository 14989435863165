import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { ForumServiceService } from 'projects/cpa/src/app/pages/forum/forum-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  notificationData: any = [];
  notificationObserver =
    this.forumServiceService.notificationObserver.asObservable();

  constructor(public modalRef: MDBModalRef, private forumServiceService: ForumServiceService, private router: Router,) { }

  ngOnInit(): void {

    this.notificationObserver.subscribe((data) => {
      if (data) {
        this.notificationData = data;
      }
    });
    this.getForumNotifications();
  }

  marksNotificaionsread(int_post_id?: any) {
    this.forumServiceService
      .marksNotificaionsread({ int_status: 1, int_post_id })
      .subscribe((res: any) => {
        if (res.status) {
          this.getForumNotifications();
        }
      });
  }

  getForumNotifications() {
    this.forumServiceService.getForumNotifications();
  }
  goToForum(
    type: number,
    subjectId: any,
    questionId: any,
    mainTopicId: any,
    subTopicId: any,
    videoId: any
  ) {
    if (type === 1) {
      this.router.navigate(['/lms/forum/mcq', subjectId, questionId]);
    } else if (type === 2) {
      this.router.navigate(['/lms/forum/sims', subjectId, questionId]);
    } else if (type === 3) {
      this.router.navigate([
        '/lms/forum/video',
        subjectId,
        mainTopicId,
        subTopicId,
        videoId,
      ]);
    } else if (type === 4) {
      this.router.navigate([
        '/lms/forum/ebook',
        subjectId,
        mainTopicId,
        subTopicId,
        0,
      ]);
    }
  }

}
