import { createSelector } from '@ngrx/store';

export const selectAuthState = state => {
  // //// console.log('state', state.auth.user);
  return state.auth;
};

export const isLoggedIn = createSelector(
  selectAuthState,
  auth => auth.loggedIn
);
export const userdetails = createSelector(
  selectAuthState,
  auth => auth.user
);
export const isLoggedOut = createSelector(
  isLoggedIn,
  loggedIn => !loggedIn
);

export const isUserLoaded = createSelector(
  selectAuthState,
  auth => auth.isUserLoaded
);
