import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

// import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
   url = `${environment.apiUrl}`;
  subjectListObserver = new Subject();
  inboxOutboxListObserver = new Subject();
  inboxOutboxCountObserver = new Subject();
  queryDetailsObserver = new Subject();
  contactDetailsObserver = new Subject();
  draftObserver = new Subject();
  constructor(private http: HttpClient) { }

  // public sendMessage(message) {
  //   this.socket.emit('new-message', message);
  // }
  // public getMessages = () => {
  //   return Observable.create((observer) => {
  //     this.socket.on('new-message', (message) => {
  //       observer.next(message);
  //     });
  //   });
  // }

  subjectList() {
    this.http.post(this.url + 'support/listSubject', {}).subscribe((res: any) => {
      if (res && res.status) {
        this.subjectListObserver.next(res['data']);
      }
    });
  }

  composeEmail(parms) {
    return this.http.post(this.url + 'support/createQuery', parms);
  }
  saveEmail(parms) {
    return this.http.post(this.url + 'support/draftQuery', parms);
  }
  indoxOutboxList(is_inbox) {
    this.http.post(this.url + 'support/querylist', { is_inbox }).subscribe((res: any) => {
      if (res && res.status) {
        //// console.log('inboxlist', res);
        this.inboxOutboxListObserver.next(res['data']);
      }
    });
  }

  indoxOutboxCount() {
    this.http.post(this.url + 'support/inboxCount', {}).subscribe((res: any) => {
      if (res && res.status) {
        //// console.log('indoxOutboxCount', res);
        this.inboxOutboxCountObserver.next(res['data']);
      }
    });
  }

  deleteQuery(email_query_id) {
    return this.http.post(this.url + 'support/deleteQuery', { email_query_id: email_query_id });
  }

  updateReadStatus(email_query_id) {
    this.http.post(this.url + 'support/updateReadStatus', { email_query_id: email_query_id }).subscribe((res: any) => {
      if (res && res.status) {
        // done thats it
      } else {
        // error reschedule again
        this.updateReadStatus(email_query_id);
      }
    });
  }

  queryDetailList(email_query_id) {
    this.http.post(this.url + 'support/queryDetail', { email_query_id: email_query_id }).subscribe((res: any) => {
      if (res && res.status) {
        //// console.log('queryDetailList', res);
        this.queryDetailsObserver.next(res['data']);
      }
    });
  }

  studentReply(params) {
    return this.http.post(this.url + 'support/studentReply', params);
  }

  draft() {
    this.http.post(this.url + 'support/draftlist', {}).subscribe((res: any) => {
      if (res && res.status) {
        //// console.log('inboxlist', res);
        this.draftObserver.next(res['data']);
      }
    });
  }

  // conatct modal api

  conatctDetailsList() {
    this.http.post(this.url + 'studentContactDetails', {courseId:1}).subscribe((res: any) => {
      if (res && res.status) {
       // //// console.log('queryDetailList', res);
        this.contactDetailsObserver.next(res['data']);
      }
    });
  }

}
