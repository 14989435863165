import { Component, OnInit } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-cpa-evolution',
  templateUrl: './cpa-evolution.component.html',
  styleUrls: ['./cpa-evolution.component.scss']
})
export class CpaEvolutionComponent implements OnInit {

  constructor(public modalRef: MDBModalRef) { }

  ngOnInit(): void { window.scroll({top:0,left:0,behavior:"smooth"});
  }

}
