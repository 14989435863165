import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadStudentAction } from 'src/app/_store/actions/student.action';

@Component({
  selector: 'app-ionic-blank',
  template:
    `<div style="margin-top:-100px;">
    <router-outlet></router-outlet>
    </div>`,
})
export class IonicBlankComponent implements OnInit {

  constructor(private store: Store<any>) { }

  ngOnInit(): void { window.scroll({top:0,left:0,behavior:"smooth"});
    this.store.dispatch(new LoadStudentAction());
  }

}
