import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LogsModalComponent } from './logs-modal.component';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { RouterModule } from '@angular/router';



@NgModule({
    declarations: [LogsModalComponent],
    imports: [
        CommonModule, MDBBootstrapModulesPro.forRoot(), RouterModule
    ],
    exports: [LogsModalComponent]
})
export class LogsModalModule { }
