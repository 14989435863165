import { NgModule } from '@angular/core';
import { EvaConfirmationComponent } from './eva-confirmation.component';
import { PipesModule } from 'src/app/_clusters/pipe/pipes.module';
import { FormsModule } from '@angular/forms';
import { ModalModule, ButtonsModule, WavesModule, MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { CommonModule } from '@angular/common';
import { EvaluationStep2ModalModule } from '../evaluation-step2-modal/evaluation-step2-modal.module';
import { NgSelectModule } from '@ng-select/ng-select';
// import { BrowserModule } from '@angular/platform-browser';


@NgModule({
  declarations: [EvaConfirmationComponent],
  imports: [
    CommonModule,
    // BrowserModule,
    PipesModule,
    FormsModule,
    ModalModule,
    ButtonsModule,
    WavesModule,
    NgSelectModule,
    EvaluationStep2ModalModule,
    MDBBootstrapModulesPro.forRoot()
  ],
  exports: [EvaConfirmationComponent],
  providers: [EvaluationStep2ModalModule],
  // entryComponents: [EvaConfirmationComponent]

})
export class EvaConfirmationModule { }
