<div class="modal-header py-2" *ngIf="!!!showFacs">
  <button type="button" class="close pull-right" aria-label="Close" (click)="hide();stateEvalStatus = 0;">
    <i class="fas fa-times"></i>
  </button>
  <h5 class="modal-title w-100 font-weight-normal" id="myModalLabel">Evaluation Payment</h5>
</div>
<div class="modal-body" [ngClass]="showFacs?'d-flex ticket_height':'fix_height'" style="background: #7a7a7a42;">
  <div class="row" [ngClass]="showFacs?'mx-auto my-0':'mx-0'">
    <ng-container *ngIf="!!!showFacs">
      <div class="col-12 my-2 d-flex">
        <label class="mr-2 degree-label my-auto" style="min-width: 30%;"><i class="fad fa-landmark-alt my-auto mr-1"
            style="font-size: 1.2rem;"></i>Evaluation Agency : <span class="mt-0 text-danger">*</span> </label>
        <input type="text" readonly class="form-control" value="Foreign Academic Credential Service - FACS">
      </div>
      <div class="col-12 my-2 d-flex">
        <label class="mr-2 degree-label my-auto" style="min-width: 30%;"><i class="fad fa-landmark-alt my-auto mr-1"
            style="font-size: 1.2rem;"></i>Stateboard: <span class="mt-0 text-danger">*</span> </label>
        <ng-select #stateboardId [items]="stateboardArray" bindLabel="stateboardName" bindValue="state_board_id"
          placeholder="Choose State Board" [(ngModel)]="stateBoardId" class="w-100"
          (change)="onchangeStateBoard($event);"></ng-select>
      </div>
    </ng-container>

    <div *ngIf="stateEvalStatus == 2" class="card z-depth-0 red lighten-5 border border-danger w-50 mx-auto mt-3">
      <div class="card-body py-0 px-2">
        <div class="card-text" aria-label="">
          <div class="mb-3" style="color: #c53952 !important;">
            <p class="mt-3 mb-2 text-center font-weight-bold" style="font-size: 1.5rem;"><i
                class="fas fa-exclamation-triangle" style="font-size: 3rem; color: #c53952 !important;"></i><br>Oops !
            </p>
            <p class="mt-1 mb-0 text-center" style="font-size: 1rem;">Looks like you have chosen an incorrect state
              board. Refer to your Game Plan tool to check the state board that has been assigned to you. You can also
              get in touch with your Game Plan SPOC to check regarding the same.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="stateEvalStatus == 1" class="card z-depth-0 green lighten-5 border border-success w-75 mx-auto mt-3">
      <div class="card-body py-0 px-2">
        <div class="card-text" aria-label="">
          <div class="mb-3" style="color:#348a3a !important;">
            <p class="mt-3 mb-2 text-center font-weight-bold" style="font-size: 1.5rem;"><i class="fas fa-check-circle"
                style="font-size: 3rem; color: #296f26 !important"></i><br>Success !</p>
            <p class="my-2 text-center" style="font-size: 1.02rem;line-height:1.7">The Evaluation agency that you have
              selected is: <br><strong>{{agency}} - {{stateBoardValue}}</strong>. <br>Please cross-check if this is the
              agency and state board advised on the email sent by your Gameplan SPOC.<br><button color="success"
                type="submit" class="mt-3" mdbBtn mdbWavesEffect style="padding: .7rem 1.14rem;"
                (click)="stateBoardStatus();">Please click here for futher process<i class="fas fa-hand-pointer ml-2"
                  style="font-size: 1.2rem;"></i></button></p>
          </div>
        </div>
      </div>
    </div>


  </div>
</div>