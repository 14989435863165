import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PaymentService } from 'src/app/_clusters/services/payment.service';


@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.scss']
})
export class PaymentSuccessComponent implements OnInit {
  // studentName: string = ""
  // TransctionID: string = ""
  loader:boolean = true;

  constructor(private paymentService: PaymentService, private router: Router) { }

  ngOnInit(): void {
    this.paymentService.paymentExecuteForFAC().subscribe((res:any)=>{
      console.log(res);
      this.loader = false;
      // this.studentName = `${res.data.payer.payer_info.first_name} ${res.data.payer.payer_info.last_name}`
      // this.TransctionID = res.data.id
      this.router.navigate(['/lms/miles-facs-payment']);
    })
  }

}
