import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class McqsService {
  Url = `${environment.apiUrl}`;
  session_id = '';
  questionDetails: BehaviorSubject<any> = new BehaviorSubject([]);
  SimsQuestionsCounts: Subject<any> = new Subject();
  wctQuestionList: Subject<any> = new Subject();
  wctQuestionData: Subject<any> = new Subject();
  wctShowAnswer = false;
  practiceExamMode = false;
  viewPracticeExamReport = false;
  wctTextArea = '';
  questionId: Subject<any> = new Subject();
  bookmark: Subject<any> = new Subject();
  attemptedQuestion: Subject<any> = new Subject();


  isSubTopicsLoaderOpen: boolean = true;
  subTopicLoaderChange: Subject<boolean> = new Subject<boolean>();

  constructor(private http: HttpClient) {
    this.subTopicLoaderChange.subscribe((e) => {
      this.isSubTopicsLoaderOpen = e
    })
  }

  toggleSidebarVisibility(e) {
    this.subTopicLoaderChange.next(e);
  }

  setSessionId(id) {
    this.session_id = id;
  }
  getSessionId() {
    return this.session_id;
  }
  getMcqCounts(
    subject_id,
    selected_topics,
    selected_questionstype,
    old_year,
    aicpa_inter?
  ) {
    const params =
      old_year == 1
        ? {
          subject_id,
          selected_topics,
          selected_questionstype,
          old_year,
          aicpa_inter,
        }
        : { subject_id, selected_topics, selected_questionstype, aicpa_inter };
    return this.http.post(this.Url + 'getMcqCounts', params);
  }
  getSelectedMcqQuestions(requestData) {
    // console.log('reww', requestData);
    return this.http.post(this.Url + 'getSelectedMcqQuestions', {
      ...requestData,
      created_from: '1',
    });
  }
  getActiveSessions(session_id) {
    return this.http.post(this.Url + 'getActiveSessions', {
      session_id,
      created_from: '1',
    });
  }
  saveAnswer(selected_option, question_id, session_id) {
    return this.http.post(this.Url + 'saveAnswer', {
      selected_option,
      question_id,
      session_id,
    });
  }
  saveFlag(question_id, session_id) {
    return this.http.post(this.Url + 'saveFlag', { question_id, session_id });
  }
  mcqSaveFlag(question_id, session_id, is_flag) {
    return this.http.post(this.Url + 'saveFlag', { question_id, session_id, is_flag });
  }
  quitrsubmitMcqSession(session_id) {
    return this.http.post(this.Url + 'quitrsubmitMcqSession', { session_id });
  }
  saveNotes(question_id, notes) {
    return this.http.post(this.Url + 'saveNotes', { question_id, notes });
  }
  getNotes(question_id) {
    return this.http.post(this.Url + 'getNotes', { question_id });
  }

  saveSimsNotes(questionId, notes) {
    return this.http.post(this.Url + 'saveSimsNotes', { questionId, notes });
  }
  getSimsNotes(question_id) {
    return this.http.post(this.Url + 'getSimsNotes', { question_id });
  }

  getWCTQuestionsCounts(maintopic_id) {
    return this.http.post(this.Url + 'getWCTQuestionsCounts', {
      maintopic_id: maintopic_id.join(','),
    });
  }
  getSimsQuestionsCounts(subject_id, topic_id, selected_questionstype) {
    this.http
      .post(this.Url + 'getSimsQuestionsCounts', {
        subject_id,
        topic_id,
        selected_questionstype,
      })
      .subscribe((res: any) => {
        if (res && res.status) {
          this.SimsQuestionsCounts.next(res.data);
        }
      });
  }
  getselectedWCTquestions(
    maintopic_id,
    questions_type,
    numberof_questions,
    session_id
  ) {
    let mainTopicData: any;
    if (maintopic_id) {
      mainTopicData = maintopic_id.join(',');
    } else {
      mainTopicData = maintopic_id;
    }
    this.http
      .post(this.Url + 'getselectedWCTquestions', {
        maintopic_id: mainTopicData,
        questions_type,
        numberof_questions,
        session_id,
      })
      .subscribe((res: any) => {
        if (res && res.status) {
          this.wctQuestionList.next(res.data);
        }
      });
  }
  saveWCTAttempts(question_id, session_id) {
    return this.http.post(this.Url + 'saveWCTAttempts', {
      answer_content: this.wctTextArea,
      question_id,
      session_id,
    });
  }
  saveWCTFlags(question_id, session_id, flag_status) {
    return this.http.post(this.Url + 'saveWCTFlags', {
      question_id,
      session_id,
      flag_status,
    });
  }
  getWCTReport(session_id) {
    return this.http.post(this.Url + 'getWCTReport', { session_id });
  }
  getVideoDetails(int_video_id) {
    return this.http.post(this.Url + 'getVideoDetails', { int_video_id });
  }
  getWebinarVideosDetails(webinar_video_id) {
    return this.http.post(this.Url + 'getWebinarVideosDetails', {
      webinar_video_id,
    });
  }
  createminimocktest(subject_id:string, maintopic_id:any,subtopic_id_encrypt:string='', mocktest_type:string='') {
    // console.log(subject_id);
    return this.http.post(this.Url + 'generateMiniMockSession', {
      subject_id,
      maintopic_id,
      subtopic_id_encrypt,
      mocktest_type
    });
  }
  createCpaInterMinimockTest(subject_id, maintopic_ids, is_certification?) {
    return this.http.post(this.Url + 'generateMiniMockSessionForInter', {
      subject_id,
      maintopic_ids,
      is_certification,
    });
  }

  getAllCpaInterMinimockTest(subject_id, is_certification?) {
    return this.http.post(this.Url + 'getMiniMockResultsForInter', {
      subject_id,
      is_certification,
    });
  }

  createMocktest(subject_id) {
    this.http
      .post(this.Url + 'create/mainmocktest', { subject_id })
      .subscribe((res: any) => {
        if (res && res.status) {
          // document.location.href = res.data.url;
        }
      });
  }
  generatePracticeMockSession(payload) {
    return this.http.post(this.Url + 'generatePracticeMockSession', payload);
  }
  fetchMcqPracticeMockquestions(payload) {
    return this.http.post(this.Url + 'fetchMcqPracticeMockquestions', payload);
  }
  SavePracticeMockMcqAnswers(payload) {
    return this.http.post(this.Url + 'SavePracticeMockMcqAnswers', payload);
  }
  flagPracticeMock(payload) {
    return this.http.post(this.Url + 'flagPracticeMock', payload);
  }
  flagSimsPracticeMock(payload) {
    return this.http.post(this.Url + 'flagSimsPracticeMock', payload);
  }
  saveMocktestScore(data) {
    return this.http.post(this.Url + 'saveMocktestScore', data);
  }
  onSubmitTestlet(payload) {
    return this.http.post(this.Url + 'onSubmitTestlet', payload);
  }
  bridgecoursescorecardbycourse() {
    return this.http.post(this.Url + 'bridgecoursescorecardbycourse', {});
  }
  getSimsTestQuestions(
    subject_id,
    selected_topics,
    selected_questionstype,
    attempting_questions_type,
    number_of_questions,
    exam_mode
  ) {
    this.http
      .post(this.Url + 'getSimsTestQuestions', {
        subject_id,
        selected_topics,
        selected_questionstype,
        attempting_questions_type,
        number_of_questions,
        exam_mode,
      })
      .subscribe((res: any) => {
        if (res && res.status) {
          document.location.href = res.data.url;
        }
      });
  }
  createQuery(params) {
    return this.http.post(this.Url + 'createQuery', { ...params });
  }
  openWindow(url) {
    // console.log('url', url)
    window.open(
      url,
      '_blank',
      'toolbar=yes,scrollbars=yes,resizable=yes,width=800,height=576'
    );
  }

  updateMinimockMocktestMcqSimsFlags(payload) {
    return this.http.post(this.Url + 'updateMinimockMocktestMcqSimsFlags', payload);
  }
  saveAndUpdateSpreadData(payload:any) {
    return this.http.post(this.Url + 'saveAndUpdateSpreadData', payload);
  }
  getSpreadData(payload:any) {
    return this.http.post(this.Url + 'getSpreadData', payload);
  }

  fetchMcqMiniMockquestions({session_id, subject_id, limit_filter}) {
    return this.http.post(this.Url + 'fetchMcqMiniMockquestions', {session_id, subject_id, limit_filter});
  }

  showBridgeCourseVideoProgress(payload){
    return this.http.post(this.Url + 'getBridgeCourseVideoCount', payload);
  }

  showBridgeCourseMCQProgress(payload){
    return this.http.post(this.Url + 'getBridgeCourseMCQCount', payload);
  }
}
