import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalOpenedSource = new Subject<NgbModalRef>();

  modalOpened$ = this.modalOpenedSource.asObservable();

  constructor(private modalService: NgbModal) { }

  open(content : any, option : any): NgbModalRef {
    const modalRef = this.modalService.open(content, option);
    this.modalOpenedSource.next(modalRef); // Notify subscribers that a modal is opened
    return modalRef;
  }
}