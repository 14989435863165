import { QuectionActions, QuestionActionTypes } from '../actions/question.action';

export interface QuestionState {
  question_details: [];
  question_data: [];
  question_id: string;
  is_attempt: number;
  loading: boolean;
  error: Error;
}
export const initialState: QuestionState = {
  question_details: undefined,
  question_data: undefined,
  is_attempt: undefined,
  question_id: undefined,
  loading: false,
  error: undefined
};
export function QuestionsReducer(state = initialState, action: QuectionActions): QuestionState {
  switch (action.type) {
    case QuestionActionTypes.LoadSearchQuestion:
      //// console.log('reducer');
      return {
        ...state,
        question_id: action.payload.question_id,
        loading: true
      };
    case QuestionActionTypes.LoadSearchQuestionSuccess:
      return {
        ...state,
        question_details: action.payload,
        loading: false
      };
    case QuestionActionTypes.LoadSearchQuestionFail:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case QuestionActionTypes.LoadQuestionDetails:
      //// console.log('reducer');
      return {
        ...state,
        is_attempt: action.payload.is_attempt,
        question_id: action.payload.question_id,
        loading: true
      };
    case QuestionActionTypes.LoadQuestionDetailsSuccess:
      return {
        ...state,
        question_data: action.payload,
        loading: false
      };
    case QuestionActionTypes.LoadQuestionDetailsFail:
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    default:
      return state;
  }
}
