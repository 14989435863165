
<!-- <div class="modal-body p-1" style="max-height: 400px; overflow-y: auto;">
 
</div> -->
<!-- <div class="modal-footer p-0">
  <button type="button" mdbBtn color="primary" [disabled]="evalnts_status == 0"
    class="relative waves-light btn btn-primary btn-md" mdbWavesEffect
    (click)="updateEducationMandate(2)">Confirm</button>
</div> -->
<div class="row mx-0" *ngIf="evalOption.length > 0">
  <div class="col-12 px-0">
    <!-- <label class="mr-2 my-auto h6-responsive">Before we proceed with the your CPA Gameplan, we would like to know
      what's your status on Evaluation or NTS? </label> -->

    <div *ngFor="let eval of evalOption">
      <ng-container *ngIf="eval.gameplan_evaluation_confirmation_id !=13">
      <div class="form-check py-1 px-0">
        <input type="radio" class="form-check-input" id="evalOption_{{eval.gameplan_evaluation_confirmation_id}}"
          name="evalOption" value="{{eval.gameplan_evaluation_confirmation_id}}" (change)="onChange($event)" mdbInput>
        <label class="form-check-label"
          for="evalOption_{{eval.gameplan_evaluation_confirmation_id}}" style="font-size:14px">{{eval.option}}</label>
      </div>
    </ng-container>
    </div>

  </div>
  <button type="button" mdbBtn color="primary" [disabled]="evalnts_status == 0"
    class="relative waves-light btn btn-primary btn-md" mdbWavesEffect
    (click)="updateEducationMandate(2)">Proceed</button>
    
</div>
<div *ngIf="testStatus">
  <app-evaluation-step2-modal></app-evaluation-step2-modal>
</div>