<div>
  <div class="modal-header py-2">
    <!-- <span style="position: absolute;
  right: 35px;" aria-label="Close" (click)="showCal=!showCal">
    <span aria-hidden="true"> <i class="fas fa-minus-square"></i> </span>
  </span> -->
    <span style="position: absolute;
  right: 10px;" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true"><i class="fas fa-times-square"></i></span>
    </span>
    <h6 class="modal-title w-100 font-weight-bold" id="myModalLabel">
      Calculator
    </h6>
  </div>
  <div class="modal_content" *ngIf="showCal">
    <iframe src="https://factory.mileseducation.com/Calculator/Calculator.html" class="calculator"
      style="overflow-y: hidden; height:300px;width:99% !important;"></iframe>
  </div>
  <!-- <div class="modal-footer">
  <button mdbBtn type="button" color="danger" size="md" (click)="modalRef.hide()" mdbWavesEffect
    style="border-radius: 5px;">Close</button>
</div> -->
</div>
